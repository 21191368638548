<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="debtName" @md-selected="getDebtSelected" :md-options="debts" @md-changed="getDebts"  @md-opened="getDebts" :class="{'md-invalid': submitted && $v.entity.debtId.$error }">
                                    <label>Công nợ</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.debtCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.debtId.isSelected">Vui lòng chọn công nợ</span>
                                </md-autocomplete>
                                <md-button @click="openDebt()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm công nợ</md-tooltip>
                                </md-button>
                            </div>
                            <div class="radio-group">
                                <label>Hình thức thanh toán</label>
                                <div class="form-control">
                                    <md-radio v-for="m in paymentMethod" :key="'m-' + m.value" v-model="entity.method" :value="m.value">{{ m.text }}</md-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.paymentCode.$error }">
                               <label for="paymentCode">Mã thanh toán</label>
                               <md-input name="paymentCode" v-model="entity.paymentCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.paymentCode.isSelected">Vui lòng nhập mã thanh toán</span>
                            </md-field>
                            <md-datepicker v-model="entity.paymentDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.paymentDate.$error }">
                                <label>Ngày thanh toán</label>
                                <span class="md-error" v-if="submitted && !$v.entity.paymentDate.required">Vui lòng chọn ngày thanh toán</span>
                            </md-datepicker>
                       </div>
                       <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="billNo">Số hóa đơn</label>
                               <md-input name="billNo" v-model="entity.billNo" ></md-input>
                            </md-field>
                            <md-datepicker v-model="entity.billDate" md-immediately :md-model-type="String">
                                <label>Ngày hóa đơn</label>
                            </md-datepicker>
                       </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="money">Số tiền công nợ</label>
                               <md-input :disabled="true" name="money" :value="formatNumber(debtMoney)"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field class="number-control" :class="{'md-invalid': submitted && $v.entity.money.$error }">
                                <div>Số tiền thanh toán:</div>
                                <number name="money" v-model="entity.money" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.money.required">Vui lòng nhập số tiền thanh toán</span>
                            </md-field>
                        </div>
                    </div>
                    <div v-if="entity.method == 1" class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="group-box">
                                <div class="group-title">Thông tin ngân hàng</div>
                                <div class="row">
                                    <div class="col l-3 m-3 c-12">
                                        <md-field>
                                            <label for="bankName">Ngân hàng</label>
                                            <md-input name="bankName" v-model="entity.bankName" ></md-input>
                                        </md-field>
                                    </div>
                                    <div class="col l-3 m-3 c-12">
                                        <md-field>
                                            <label for="bankNo">Số tài khoản</label>
                                            <md-input name="bankNo" v-model="entity.bankNo" ></md-input>
                                        </md-field>
                                    </div>
                                    <div class="col l-3 m-3 c-12">
                                        <md-field>
                                            <label for="bankOwner">Chủ tài khoản</label>
                                            <md-input name="bankOwner" v-model="entity.bankOwner" ></md-input>
                                        </md-field>
                                    </div>
                                    <div class="col l-3 m-3 c-12">
                                        <md-field>
                                            <label for="bankBranch">Chi nhánh</label>
                                            <md-input name="bankBranch" v-model="entity.bankBranch" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <debtList ref="debtList" @close="closeDebt"/>
        <companyList ref="companyList" title="khách hàng" :type="customerType" @close="closeCompany"/>
   </div>
</template>
<script>
    import paymentService from '../../../api/paymentService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import companyList from '../../../components/popup/_CompanyList.vue';
    import staffList from '../../../components/popup/_StaffList.vue';
    import debtList from '../../../components/popup/_DebtCustomerList.vue';
    import debtCustomerService from '../../../api/debtCustomerService';

    export default ({
        components: {
            staffList,
            companyList,
            debtList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thanh toán công nợ'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, debtId: 0, staffId: 0, customerId: 0, paymentCode: '', paymentNumber: 0, billNo: '', billDate: '', money: 0, method: 1, bankName: '', bankNo: '', bankBranch: '', bankOwner: '', paymentDate: common.dateNow, type: 1 },
                customerType: common.companyType.customer,
                companyName: '',
                companies: [],
                staffName: '',
                staffs: [],
                debts: [],
                debtName: '',
                paymentMethod: common.paymentMethod,
                debtMoney: 0,
                number: common.currencyF
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật thanh toán công nợ khách hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới thanh toán công nợ khách hàng';
               this.getByAccount();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Debt
            closeDebt(item){
                this.debtName = item.debtCode;
                this.entity.debtId = item.id;
                this.debtMoney = item.amount;
                this.entity.paymentCode = 'TT-' + item.debtCode;
                this.$refs.debtList.close();
            },

            getDebtSelected(val){
                this.entity.debtId = val.id;
                this.debtName = val.debtCode;
                this.debtMoney = val.amount;
                this.entity.paymentCode = 'TT-' + val.debtCode;
            },

            openDebt(){
                if(this.entity.customerId === 0){
                    messageBox.showInfo("Vui lòng chọn khách hàng trước");
                }
                else{
                    this.$refs.debtList.open(this.entity.customerId);
                }
            },

            getDebts(searchTerm){
                if(this.entity.customerId === 0){
                    messageBox.showInfo("Vui lòng chọn khách hàng trước");
                }
                else{
                    let search = { pageIndex: 1, pageSize: 30, type: 1, companyId: this.entity.customerId, alias: searchTerm };
                    debtCustomerService.getForPayments(search).then((response) => {
                        if(response.statusCode == 200){
                            this.debts = response.data.items;
                        }
                        else{
                            messageBox.showWarning(response.data);
                        }
                    }).finally();
                }
            },

            //Customer
            closeCompany(item){
                this.companyName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.entity.customerId = val.id;
                this.companyName = val.companyAlias;
            },

            openCompany(){
                this.$refs.companyList.open(this.customerType);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            
            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.type = 1;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                paymentService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                paymentService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/payment-customer');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                paymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.companyName = response.data.customer.companyAlias;
                        this.debtName = response.data.debtCustomer.debtCode;
                        this.staffName = response.data.staff.fullName;
                        this.debtMoney = response.data.debtCustomer.amount;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.staffName = response.data.fullName;
                            this.entity.deptId = response.data.department.id;
                            this.entity.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getCode(staffId){
                this.setLoading(true);
                debtCustomerService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.paymentCode = response.data.code;
                        this.entity.paymentNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/payment-customer');
            }
        },
        watch: { 
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            debtName: function (val) { 
                if(val == ''){
                    this.entity.debtId = 0;
                }
            },
        },
        validations: {
            entity: {
                debtId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                money: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                paymentCode: { required },
                paymentDate: { required },
            }
       }
     })

</script>
